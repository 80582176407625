* {
  padding: 0;
  margin: 0;
}

/* Helveticaneue */
@font-face {
  font-family: 'Helveticaneue';
  font-style: normal;
  font-weight: 400;
  src: url('./assets//fonts/helveticaneue_regular.otf');
}

@font-face {
  font-family: 'MyriadPro';
  font-style: normal;
  font-weight: 400;
  src: url('./assets//fonts/MyriadPro-Regular.otf');
}